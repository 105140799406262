import React,{useEffect} from 'react';
import Navbar from 'components/website/Navbar/Navbar';
import BusinessManagementHead from 'components/website/BusinessManagementHead/BusinessManagementHead';
import BMAbout from 'components/website/BMAbout/BMAbout';
import BMWhyWeHere from 'components/website/BMWhyWeHere/BMWhyWeHere';
import BMwhatWeDo from 'components/website/BMWhatWeDo/BMwhatWeDo';
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import FixedChat from 'components/website/FixedChat/FixedChat';


const BusinessManagement = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <BusinessManagementHead/>
            <BMAbout/>
            <BMWhyWeHere/>
            <BMwhatWeDo/>
            <FixedChat/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default BusinessManagement;
