import React from 'react';
import './BusinessManagement.css'
import img from 'assets/img/carousel-3.jpg'

const BusinessManagementHead = () => {
  return (
    <div className="bhead">
    <div className="position-relative">
        <div className="image">
          <img src={img} alt="" />
        </div>
        <div className="text">
          <h3>BUSINESS MANAGEMENT CONSULTING</h3>
        </div>
    </div>
    </div>
  );
}

export default BusinessManagementHead;
