import React from 'react';
import './EAbout.css'
import { Container } from 'react-bootstrap';
import img from 'assets/img/ecommerce.jpg'

const EAbout = () => {
    return (
        <div className='mt-5'>
        <Container>
        <div className="ecom mt-5">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="eabout">
                            <h2>HIFI</h2>

                            <h5>It’s not a detergent liquid but a beauty queen.</h5>
                        </div>
                        <div className="content mt-3">
                        <p>We make your buying easier. You can buy trustable products from our own company with a money back guarantee on terms and conditions. Our products like Hi Fi Fabric smooth wash solution, Garments, etc can be purchased easily.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="eabout-image mt-3">
                            <img src={img} alt="image"/>
                        </div>
                    </div>
                </div>
                </div>
        </Container>
    </div>
    );
}

export default EAbout;
