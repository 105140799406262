import React,  { useState } from 'react';
import Form from "react-bootstrap/Form";
import './LTable.css'
import Button from 'react-bootstrap/Button';
import { alertPromise } from "components/AppAlert/AppAlert";
import privateAPI from "api/privateAPI";
import { useUserStore } from "stores/useUserStore";


const LTable = () => {
  const { user } = useUserStore((s) => s);
  const [legal, setLegal] = useState({
    name:"", 
    phone:"", 
    email:"", 
    address:"", 
    complaint:"", 
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setLegal({ ...legal, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await alertPromise(privateAPI.post("/legal", { ...legal, user_id: user?.user_id }));
    e.target.reset();
  };

    return (
        <div>
          <div className="dash-legal mt-4">
            <h4>Legal Consulting</h4>
          </div>
            <div className="row mt-3">
            <div className="col-lg-5 col-sm-12 col-md-6">
            <div className="ltable">

            
            <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" onChange={onChange} name="name" placeholder="Enter name " />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="tel" onChange={onChange} name="phone" placeholder="Enter Mobile number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={onChange} name="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" onChange={onChange} name="address" placeholder="Enter address" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Complaints / Case</Form.Label>
                <Form.Control type="text" onChange={onChange} name="complaint"placeholder="Enter Complaints / Case" />
              </Form.Group>

              <Button variant="primary me-3" type='submit'>Submit</Button>
          </Form>
          </div>
          <div className="col-lg-7 col-md-6">

          </div>
            </div>
          </div>
          
        </div>
    );
}

export default LTable;
