import React from 'react';
import useFetch from "hooks/useFetch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const ViewSoftware = () => {
    const { data, loading, error } = useFetch("/softwares");
    console.log(data);
  const columns = [
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Contact Person",
      dataField: "contact_person",
    },
    {
      text: "Phone Number",
      dataField: "phone",
    },
    {
        text: "Desigination",
        dataField: "designation",
      },
    {
      text: "Email",
      dataField: "email",
    },
    {
      text: "Address",
      dataField: "address",
    },
    {
      text: "Requirements",
      dataField: "requirement",
    },
  ];
    return (
        
      <ToolkitProvider
        keyField="id"
        data={data?.appData ? data?.appData : []}
        columns={columns}
        search
      >
        {(props) => (
          <>
           

            <BootstrapTable
              {...props.baseProps}
              wrapperClasses="table-responsive bg-white shadow-sm"
            />
          </>
        )}
      </ToolkitProvider>
    );
}

export default ViewSoftware;
