import React from 'react';
import "./EHead.css";
import img from "assets/img/carousel-3.jpg";

const EHead = () => {
    return (
        <div className="position-relative">
      <div className="image">
        <img src={img} alt="" />
      </div>
      <div className="text">
        <h3>Consumer Goods</h3>
      </div>
    </div>
    );
}

export default EHead;
