import React from "react";
import "./Register.css";
import { Container } from "react-bootstrap";
import img from "assets/img/register.jpg";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import publicAPI from "api/publicAPI";
import { alertPromise } from "components/AppAlert/AppAlert";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = publicAPI.post("/register", {
      name,
      email,
      phone: number,
      password,
    });
    await alertPromise(res);
    navigate("/");
  };

  return (
    <div className="mt-5">
      <Container>
        <div className="register">
          <div className="row">
            <div className="col-lg-5 col-sm-12 col-md-6">
              <div className="rcontent mt-5">
                <h5>Register</h5>
                <div className="form mt-4">
                  <Form onSubmit={handleSubmit}>
                    <FloatingLabel controlId="floatingInput" label="Name" className="mb-3" onChange={(e) => setName(e.target.value)}>
                      <Form.Control type="name" placeholder="name" />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3" onChange={(e) => setEmail(e.target.value)}>
                      <Form.Control type="email" placeholder="name@example.com" />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingInput" label="Phone Number">
                      <Form.Control type="number" placeholder="phonenumber" className="mb-3" onChange={(e) => setNumber(e.target.value)} />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingPassword" label="Password">
                      <Form.Control type="password" placeholder="Password" className="mb-3" onChange={(e) => setPassword(e.target.value)} />
                    </FloatingLabel>

                    <div className="button mt-3">
                      <Button variant="primary" type="submit">
                        Register
                      </Button>
                    </div>

                    <div className="already mt-4">
                      <p>
                        Account Already Exists? <NavLink to="/login">Login</NavLink>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 col-md-6">
              <div className="register-image">
                <img src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Register;
