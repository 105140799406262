import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Navbar.css";
import img from "assets/img/logo.png";
import { NavLink } from "react-router-dom";
import { ImWhatsapp } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import logimg from "assets/img/login.jpg";
import { alertPromise } from "components/AppAlert/AppAlert";
import publicAPI from "api/publicAPI";

function BasicExample() {
  const [show, setShow] = useState(true);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data } = await alertPromise(publicAPI.post("/login", { phone, password }));
    localStorage.setItem("MYINCORP_TOKEN", data?.appData?.token);
    localStorage.setItem("MYINCORP_USERID", data?.appData?.user?.user_id);
   
    handleClose();
  };


  return (
    <>
      <Navbar bg="light" expand="lg" fixed="top" className="app-navbar navbar">
        <div className="logo ms-2">
          <NavLink to="/">
            <img src={img} alt="logo" />
          </NavLink>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navlink me-auto">
            <NavLink to="/">Home</NavLink>
            <a href="/#about">About</a>
            <a href="#Contact">Contact</a>
            <NavDropdown title="My Incorp" id="basic-nav-dropdown">
              <NavDropdown.Item  className="navdropdown">
                <NavLink to="/business-Management" >Business Manangement Consulting
                </NavLink>
                
              </NavDropdown.Item>
              <NavDropdown.Item  className="navdropdown"><NavLink to="/software">Software Consulting</NavLink>
                
              </NavDropdown.Item>
              <NavDropdown.Item  className="navdropdown"><NavLink to="/legal">Legal Consulting</NavLink>
                
              </NavDropdown.Item>
              <NavDropdown.Item  className="navdropdown"><NavLink to="/wellness">Wellness Consulting</NavLink>
                
              </NavDropdown.Item>
              <NavDropdown.Item  className="navdropdown"><NavLink to="/ecommerce"> E - Commerce</NavLink>
               
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink to="/career">Career</NavLink>
            <NavDropdown title="Reach Us" id="basic-nav-dropdown">
              <NavDropdown.Item href="https://wa.me/message/34UPPFHDFJLSI1">
                <ImWhatsapp /> Whatsapp
              </NavDropdown.Item>
              <NavDropdown.Item href="tel:+916381796981">
                <FiPhoneCall /> Call
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>

          <div className="logreg">
            <Nav className="ms-auto">
             
                <div className="login me-2">
                  <NavLink to="/dashboard" className="btn btn-primary">
                    Dashboard
                  </NavLink>
                </div>
           
                  <div className="login me-2">
                    <NavLink to="#" className="btn btn-primary" onClick={handleShow}>
                      Login
                    </NavLink>
                  </div>
                  <div className="Register me-2">
                    <NavLink to="/register" className="btn btn-outline-primary">
                      Register
                    </NavLink>
                  </div>
          
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>

      <Modal show={show} onHide={handleClose} animation={false} className="model">
        <Modal.Header closeButton>
          <Modal.Title className="model-title">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="logimage">
            <img src={logimg} alt="" />
          </div>
          <form onSubmit={handleSubmit}>
            <FloatingLabel controlId="floatingTel" label="Phone Number" className="mb-3">
              <Form.Control type="tel" placeholder="Phone Number" onChange={(e) => setPhone(e.target.value)} />
            </FloatingLabel>

            <FloatingLabel controlId="floatingPassword" label="Password">
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </FloatingLabel>

            <button className="btn btn-primary mt-3 logbutton" type="submit">
              Login
            </button>
            <div className="forget-password mt-1">
              <NavLink to="#"><p>Forget Password ?</p></NavLink>
            </div>
            <div className="sign-up">
            <p>Dont have an account? <NavLink to="/register">Sign Up</NavLink></p>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BasicExample;
