import { useState, useEffect } from "react";
import privateAPI from "api/privateAPI";

function useFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading("loading...");
    setData(null);
    setError(null);
    privateAPI
      .get(url)
      .then((res) => {
        setLoading(false);
        res.data && setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
    return () => {
      setData([]);
    };
  }, [url]);

  return { data, loading, error };
}
export default useFetch;
