import React from 'react';
import './Footer.css';
import { NavLink } from 'react-router-dom';
import {AiFillFacebook} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillTwitterSquare} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'

const Footer = () => {
    return (
        <div>
        <div className="footer mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="footer-about mt-5">
                            <h2>ABOUT</h2>
                            <p><b>MY INCORP</b> is a consulting and manufacturing-based company established in 2018 with the goal of achieving smooth washing detergent solutions. Although our initial focus was on detergent solutions, the company has now expanded into various categories providing multiple products and services to our customers. Our products and services include Garments, Pharma Nutrients products, business consulting, software consulting, wellness consulting, and legal consulting. We have marketing offices across India, Chicago, and Atlanta, USA., and serving anywhere remotely. Our goal is to serve you with products and services matching the international grades.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="footer-categories mt-5">
                            <h2>CATEGORIES</h2>
                            <NavLink to="/business-Management">Business Management Consulting</NavLink>
                            <NavLink to="/software">Software Consulting</NavLink>
                            <NavLink to="/legal">Legal Consulting</NavLink>
                            <NavLink to="/wellness">Wellness Consulting</NavLink>
                            <NavLink to="/ecommerce">E - Commerce</NavLink>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12">
                        <div className="quick-links mt-5">
                            <h2>QUICK LINKS</h2>
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="#Categories">Categories</NavLink>
                            <NavLink to="#about">About us</NavLink>
                            <NavLink to="#Contact">Contact us</NavLink>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-6">
                        <div className="copy-rights">
                            <p>Copyrights @2017 All rights powered by Alphax</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 col-md-6">
                        <div className="social-medias">
                            <NavLink to="#"><AiFillFacebook/></NavLink>
                            <NavLink to="#"><AiFillInstagram/></NavLink>
                            <NavLink to="#"><AiFillTwitterSquare/></NavLink>
                            <NavLink to="#"><AiFillLinkedin/></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    );
}

export default Footer;
