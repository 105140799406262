import React from "react";
import { Container } from "react-bootstrap";
import "./CareerForm.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const CareerForm = () => {
  return (
    <div>
      <Container>
        <div className="career mt-5">
          <h2>Career</h2>
        </div>
        <div className="row">
          <div className="col-lg-2 col-sm-12 col-md-12"></div>
          <div className="col-lg-8 col-sm-12 col-md-12">
            <div className="careerbox">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalName"
                >
                  <Form.Label column sm={2}>
                    Name
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control type="name" placeholder="Enter Name" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalText"
                >
                  <Form.Label column sm={2}>
                    Address
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control type="address" placeholder="Enter Address" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalText"
                >
                  <Form.Label column sm={2}>
                    Qualification
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control type="text" placeholder="Enter your Qualification" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalTel"
                >
                  <Form.Label column sm={2}>
                    Number
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control type="tel" placeholder="Enter Phone Number" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >
                  <Form.Label column sm={2}>
                    Email
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control type="email" placeholder="Enter Email" />
                  </Col>
                </Form.Group>
                
              </Form>
              <div className="careerbutton">
            <Button variant="primary me-3">Submit</Button>
              <Button variant="outline-dark ">Cancel</Button>
            </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12 col-md-12"></div>
        </div>
      </Container>
    </div>
  );
};

export default CareerForm;
