import React, { useState } from "react";
import "./STable.css";
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import { alertPromise } from "components/AppAlert/AppAlert";
import privateAPI from "api/privateAPI";
import { useUserStore } from "stores/useUserStore";



const STable = () => {
  const { user } = useUserStore((s) => s);
  const [software,setSoftware]= useState({
    name: "",
    contact_person:"",
    phone:"", 
    designation:"",
    email:"", 
    address:"", 
    requirement:"",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setSoftware({ ...software, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await alertPromise(privateAPI.post("/software", { ...software, user_id: user?.user_id }));
    e.target.reset();
  };

  return (
    <div>
      <div className="dash-software mt-4">
        <h4>Software Consulting</h4>
      </div>
          <div className="row mt-3">
            <div className="col-lg-5 col-sm-12 col-md-6">
            <div className="stable">

            
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name"  onChange={onChange} name="name" placeholder="Enter name " />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Contact Person</Form.Label>
                <Form.Control type="name" onChange={onChange} name="contact_person" placeholder="Enter contact person" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control type="tel" onChange={onChange}name="phone"  placeholder="Enter Phone number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Desigination</Form.Label>
                <Form.Control type="text" onChange={onChange} name="designation" placeholder="Enter desigination" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={onChange} name="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" onChange={onChange} name="address" placeholder="Enter address" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Requirements</Form.Label>
                <Form.Control type="text" onChange={onChange} name="requirement" placeholder="Enter requirements" />
              </Form.Group>

              <Button variant="primary me-3" type="submit">Submit</Button>
          </Form>
          </div>
          <div className="col-lg-7 col-md-6">

          </div>
            </div>
          </div>
          
        </div>

  );
};

export default STable;
