import React, {useEffect} from 'react';
import Navbar from 'components/website/Navbar/Navbar';
import Carousal from 'components/website/Carousal/Carousel';
import About from 'components/website/AboutUs/AboutUs';
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import Category from 'components/website/Category/Category';
import Product from 'components/website/Products/Product';
import FixedChat from 'components/website/FixedChat/FixedChat';

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <Carousal/>
            <Category/>
            <Product/>
            <About/>
            <FixedChat/>
            <Contact/>
            <Footer/>
            
        </div>
    );
}

export default Home;
