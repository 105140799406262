import React from 'react';
import { Container } from 'react-bootstrap';
import './LGoals.css'
import img from 'assets/img/goal.jpg'
import {BsArrowRightCircle} from 'react-icons/bs'

const LGoals = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="lgoals">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <img src={img} alt="" width="100%"/>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="lpoints">
                                <h2>Our Main Goals</h2>
                                <p><BsArrowRightCircle/> To provide the high quality legal services available anywhere; </p>
                                <p><BsArrowRightCircle/> To be an integral part of each individual’s success;</p>
                                <p><BsArrowRightCircle/> To recruit, retain and develop the brightest legal talent.</p>
                                <p><span>We as a company, strive for long-term, partnership with our customers in order to offer the best overall solution for your legal requirements.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default LGoals;
