import React from 'react';
import { Container } from 'react-bootstrap';
import './AboutUs.css';
import img from "assets/img/about.jpg"

const AboutUs = () => {
    return (
        <div id="about">
            <Container>
                <div className="about mt-5">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="About-Us">
                            <h2>About Us</h2>
                        </div>
                        <div className="content mt-3">
                        <p><span>MY INCORP</span> is a consulting and manufacturing-based company established in 2018 with the goal of achieving smooth washing detergent solutions. Although our initial focus was on detergent solutions, the company has now expanded into various categories providing multiple products and services to our customers. Our products and services include Garments, Pharma Nutrients products, business consulting, software consulting, wellness consulting, and legal consulting. We have marketing offices across India, Chicago, and Atlanta, USA., and serving anywhere remotely. Our goal is to serve you with products and services matching the international grades.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="about-image mt-3">
                            <img src={img} alt="image"/>
                        </div>
                    </div>
                </div>

                <div className="myincorp">
                <div className="row mt-3">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="point1">
                             <h5><span>MY INCORP</span> targets consulting services and product manufacturing for building futuristic ideas with the best collective knowledge. We deliver products with a confronting spirit, trust, and relationship.</h5>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="point2">
                        <h5>Our consulting services include BUSINESS MANAGEMENT CONSULTING, SOFTWARE CONSULTING, LEGAL CONSULTING, AND WELLNESS CONSULTING.</h5>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="point3">
                        <h5>Our manufactured products include Hi-Fi fabric DETERGENT, FABRIC AND GARMENTS, LEATHER, and PHARMA PRODUCTS.</h5>
                        </div>
                    </div>
                </div>
                </div>

                </div>
            </Container>
        </div>
    );
}

export default AboutUs;

