import React from "react";
import "./LHead.css";
import img from "assets/img/carousel-3.jpg";

const LHead = () => {
  return (
    <div className="position-relative">
      <div className="image">
        <img src={img} alt="" />
      </div>
      <div className="text">
        <h3>LEGAL CONSULTING</h3>
      </div>
    </div>
  );
};

export default LHead;
