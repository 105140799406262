import React from 'react';
import './BMAbout.css'
import { Container } from 'react-bootstrap';
import img from 'assets/img/business management.jpg'

const BMAbout = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="management">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="content">
                                <p>“Strategy and Analytics helps create ambitious visions and take decisive motion with the belief that comes from main insight. It architects techniques for increasing transformation and gaining creation”</p>
                                <p>The Business Management consulting services of My INCORP use appropriate strategies and analytics for virtual innovation to release commercial enterprise gain via the energy of technology.</p>
                                <p>Strategic Design lies at the heart of an organization's success. It involves making the right decisions at challenging times and also making moves to evaluate the business’s future. To set a prevailing strategy, leaders must validate a corporation's knowledge of the dynamics in innovation and technology, and gear for securing boom possibilities and decreasing risk.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="bimage">
                            <img src={img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default BMAbout;
