import React from "react";
import { Container } from "react-bootstrap";
import "./Product.css";
import img from 'assets/img/product.png';
import {FaRupeeSign} from 'react-icons/fa';

const Product = () => {
  return (
    <div className="mt-5">
      <Container>
        <div className="row">
          <div className="col-lg-4 col-sm-12 col-md-6">
          <div className="product-box">
                <div className="items">
                  <img src={img} alt=""/>
                      <div className="row">
                        <div className="col-8">
                          <h3>Detergent Liquid</h3>
                        </div>
                        <div className="col-4">
                          <h4><FaRupeeSign/> 100</h4>
                        </div>
                      </div>
                    
                    <p>Smooth Wash Solution</p>
          </div>
          </div>

          </div>
        </div>
            
      </Container>
    </div>
  );
};

export default Product;
