import React from "react";
import "./SFaq.css";
import Accordion from 'react-bootstrap/Accordion';
import { Container } from "react-bootstrap";

const SFaq = () => {
  return (
    <div className="mt-5">
      <Container>
        <div className="faq">
          <h2>Common few queries are answered here related to My Incorp</h2>
        </div>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>About Our Software</Accordion.Header>
        <Accordion.Body>
        We leads you to innovation by consulting and delivering innovative idea to your requirements with ERPs, Software Development Application, Mobile and Web Applications, Desktop Applications, ITES. We cover most of the applications used for the manufacturing industry, Freight and Supply chain industry, consumer industry, Healthcare industry, Travel and Logistics, and Application and Maintenance.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      </Container>
    </div>
  );
};

export default SFaq;
