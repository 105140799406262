import React from 'react';
import { Container } from 'react-bootstrap';
import './LWhereWeServe.css';
import {AiOutlineCheckCircle} from 'react-icons/ai'

const LWhereWeServe = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="box">
                    <div className="lhead">
                         <h2>Where We Serve</h2>
                    </div>
                    <div className="whereweserve">
                        <p>We serve in both the criminal and civil areas of law; including the  cases in the following areas:</p>
                        <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="llist">
                                        <p><AiOutlineCheckCircle/> Human Rights</p>
                                        <p><AiOutlineCheckCircle/> Cyber Crime</p>
                                        <p><AiOutlineCheckCircle/> Marketing & Promotions</p>
                                        <p><AiOutlineCheckCircle/> Cybersecurity & Data Privacy</p>
                                        <p><AiOutlineCheckCircle/> Debt Finance</p>
                                        <p><AiOutlineCheckCircle/> Debtor Experience</p>
                                        <p><AiOutlineCheckCircle/> Derivatives</p> 
                                        <p><AiOutlineCheckCircle/> Energy and Infrastructure</p>
                                        <p><AiOutlineCheckCircle/> Employment & Benefits Financial Institutions</p>
                                        <p><AiOutlineCheckCircle/> Growth Equity & Venture Capital</p>
                                        <p><AiOutlineCheckCircle/> Intellectual Property</p>
                                        <p><AiOutlineCheckCircle/> International Trade & National Security</p>
                                        <p><AiOutlineCheckCircle/> Management & Credit Opportunities</p>
                                        <p><AiOutlineCheckCircle/> Patent Office Post-Grant Review</p>
                                        <p><AiOutlineCheckCircle/> Real Estate REITs</p>
                                        <p><AiOutlineCheckCircle/> Takeover Defense Sourcing & Information Technology Special Situations Tax</p>
                                        <p><AiOutlineCheckCircle/> Trusts & Estates Workplace Compliance & Investigations.</p>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="llist">
                                        <p><AiOutlineCheckCircle/> Bail/Anticipatory Bail</p>
                                        <p><AiOutlineCheckCircle/> Drug Cases</p>
                                        <p><AiOutlineCheckCircle/> Antitrust & Competition</p>
                                        <p><AiOutlineCheckCircle/> Class Action</p>
                                        <p><AiOutlineCheckCircle/> Asset</p>
                                        <p><AiOutlineCheckCircle/> Congressional Investigations</p>
                                        <p><AiOutlineCheckCircle/> Copyright Litigation and Counseling Corporate Governance</p>
                                        <p><AiOutlineCheckCircle/> Energy Regulatory</p>
                                        <p><AiOutlineCheckCircle/> Financial Technology</p>
                                        <p><AiOutlineCheckCircle/> Insurance Transactions & Regulatory</p>
                                        <p><AiOutlineCheckCircle/> International & Cross-Border Insolvency</p>
                                        <p><AiOutlineCheckCircle/> Internet & Social Media</p>
                                        <p><AiOutlineCheckCircle/> Licensing Life Sciences Intellectual Property Litigation M&A Litigation</p>
                                        <p><AiOutlineCheckCircle/> Proceedings Private</p>
                                        <p><AiOutlineCheckCircle/> Restructuring Securities & Futures</p>
                                        <p><AiOutlineCheckCircle/> Tax Disputes Technology & IP Transactions</p>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="llist">
                                        <p><AiOutlineCheckCircle/> Animal Abuse</p>
                                        <p><AiOutlineCheckCircle/> Advertising</p>
                                        <p><AiOutlineCheckCircle/> Appeals</p>
                                        <p><AiOutlineCheckCircle/> Litigation</p>
                                        <p><AiOutlineCheckCircle/> Consumer & Retail Transactions</p>
                                        <p><AiOutlineCheckCircle/> Creditors & Committees</p>
                                        <p><AiOutlineCheckCircle/> Employment & Labor</p>
                                        <p><AiOutlineCheckCircle/> Engineering and Construction Litigation</p>
                                        <p><AiOutlineCheckCircle/> Government Contracts</p>
                                        <p><AiOutlineCheckCircle/> Healthcare & Life Sciences Transactions</p>
                                        <p><AiOutlineCheckCircle/> International Arbitration</p>
                                        <p><AiOutlineCheckCircle/> Investment Funds ITC Section 337 Proceedings</p>
                                        <p><AiOutlineCheckCircle/> Mergers & Acquisitions Open Source Software Out-of-Court Restructurings</p>
                                        <p><AiOutlineCheckCircle/> Equity Private</p>
                                        <p><AiOutlineCheckCircle/> Enforcement Securities Litigation</p>
                                        <p><AiOutlineCheckCircle/> Trade Secrets Litigation</p>

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="llist">
                                        <p><AiOutlineCheckCircle/> Finance & Securitization</p>
                                        <p><AiOutlineCheckCircle/> Banking & Financial Institutions</p>
                                        <p><AiOutlineCheckCircle/> Capital Markets</p>
                                        <p><AiOutlineCheckCircle/> Consumer Protection</p>
                                        <p><AiOutlineCheckCircle/> Crisis Response</p>
                                        <p><AiOutlineCheckCircle/> Employee Benefits</p>
                                        <p><AiOutlineCheckCircle/> Employment Litigation and Counseling</p>
                                        <p><AiOutlineCheckCircle/> Environmental ESG & Impact Executive Compensation</p>
                                        <p><AiOutlineCheckCircle/> Regulatory & Internal Investigations</p>
                                        <p><AiOutlineCheckCircle/> Insurance Litigation</p>
                                        <p><AiOutlineCheckCircle/> International Risk & Investigations</p>
                                        <p><AiOutlineCheckCircle/> Joint Ventures & Strategic Alliances Liability</p>
                                        <p><AiOutlineCheckCircle/> Patent Infringement Litigation</p>
                                        <p><AiOutlineCheckCircle/> Investment & Family Office Product Liability</p>
                                        <p><AiOutlineCheckCircle/> Shareholder Activism & Hostile</p>
                                        <p><AiOutlineCheckCircle/> Trademark Litigation & Counseling Transactional Trials</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default LWhereWeServe;
