import React from 'react';
import './Contact.css';
import { Container } from 'react-bootstrap';

const Contact = () => {
    return (
        <div id="Contact">
            <Container>
                <div className="contact mt-5">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 col-md-6">
                            <div className="Contact-Us">
                                <h2>Contact Us</h2>
                                <div className="form-details mt-2">
                                  <form>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                        />
                                        <input
                                          type="email"
                                          className="form-control mt-2"
                                          placeholder="Email"
                                        />

                                    <textarea
                                      className="form-control mt-2"
                                      rows="3"
                                      required
                                      placeholder="Message"
                                    ></textarea>
                                    <div className="contact-button mt-2">
                                      <button type="submit" className="mt-2 btn form-control">
                                        Send Message
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            
                        </div>
                        <div className="col-lg-7 col-sm-12 col-md-6">
                          <div className="map mt-4">
                                <iframe
                                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10996.584059744486!2d80.20288821323618!3d12.979553124440304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d8901c01c85%3A0x840a22a3d3e8b82b!2sCoal%20Barbecues!5e0!3m2!1sen!2sin!4v1666416812507!5m2!1sen!2sin"
                                  width="100%"
                                  height="340px"
                                  title='map'
                                  style={{ border: "0px" }}
                                  loading="lazy"
                                  referrerPolicy="no-referrer-when-downgrade"/>
                                </div>
                            </div>
                    </div>
                </div>
            </Container>        
        </div>
    );
}

export default Contact;
