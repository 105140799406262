import React, { useState } from "react";
import "./BTable.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { alertPromise } from "components/AppAlert/AppAlert";
import privateAPI from "api/privateAPI";
import { useUserStore } from "stores/useUserStore";

const BTable = () => {
  const { user } = useUserStore((s) => s);
  const [business, setBusiness] = useState({
    business_name: "",
    contact_person: "",
    phone: "",
    email: "",
    address: "",
    company_type: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setBusiness({ ...business, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await alertPromise(privateAPI.post("/business", { ...business, user_id: user?.user_id }));
    e.target.reset();
  };

  return (
    <div>
      <div className="dash-business mt-4">
        <h4>Business Management Consulting</h4>
      </div>
      <div className="row mt-3">
        <div className="col-lg-5 col-sm-12 col-md-6">
          <div className="btable">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Business Firm Name</Form.Label>
                <Form.Control type="text" onChange={onChange} name="business_name" placeholder="Enter Firm name " />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Contact Person</Form.Label>
                <Form.Control type="text" onChange={onChange} name="contact_person" placeholder="Enter name" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control type="number" onChange={onChange} name="phone" placeholder="Enter Phone number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={onChange} name="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" onChange={onChange} name="address" placeholder="Enter address" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridText" className="mb-3">
                <Form.Label>Company type</Form.Label>
                <Form.Select onChange={onChange} name="company_type">
                  <option value="">Select Company Type</option>
                  <option>Prop</option>
                  <option>Patnership</option>
                  <option>LLC</option>
                  <option>Pvt Ltd</option>
                  <option>Other</option>
                </Form.Select>
              </Form.Group>

              <Button variant="primary me-3" type="submit">
                Submit
              </Button>
            </Form>
          </div>
          <div className="col-lg-7 col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default BTable;
