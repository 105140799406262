import React from 'react';
import { Container } from 'react-bootstrap';
import './SList.css';
import {AiOutlineCheckCircle} from 'react-icons/ai'


const SList = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="box">
                    <div className="swhatwedo">
                         <h2>What Do We Do?</h2>
                    </div>
                    <div className="whatwedo">
                        <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="slist">
                                        <p><AiOutlineCheckCircle/> Provide complete “SCM” Solution</p>
                                        <p><AiOutlineCheckCircle/> Seamless Information flow, Customized workflow, Grips on Inventory and effective credit controls, Partner management, Transaction visibility, and Decision Making, etc,.</p>
                                        <p><AiOutlineCheckCircle/> Warranty Management System & Warranty Claim System</p>
                                        <p><AiOutlineCheckCircle/> Fit into After Sales Services - Telecom Products, IT products and Managed Print Services Segments</p>
                                        <p><AiOutlineCheckCircle/> Call center management</p>
                                        <p><AiOutlineCheckCircle/> Workforce Management</p>
                                        <p><AiOutlineCheckCircle/> Forward & Reverse logistics movements</p>
                                        <p><AiOutlineCheckCircle/> Defective Management</p>
                                        <p><AiOutlineCheckCircle/> Partners/Vendor Claim Management</p>
                                        <p><AiOutlineCheckCircle/> Freight & Courier Management</p>
                                        <p><AiOutlineCheckCircle/> Financial and accounting Management</p>
                                        <p><AiOutlineCheckCircle/> Partner Management</p> 
                                        <br/>

                                        <p><span>Annual Maintenance Contract Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Funnel Management</p>
                                        <p><AiOutlineCheckCircle/> Bid Management</p>
                                        <p><AiOutlineCheckCircle/> Contract booking</p>
                                        <p><AiOutlineCheckCircle/> Technical Call center</p>
                                        <p><AiOutlineCheckCircle/> Field Service</p>
                                        <p><AiOutlineCheckCircle/> Central/Regional warehouse</p>
                                        <p><AiOutlineCheckCircle/> Inventory Management</p>
                                        <p><AiOutlineCheckCircle/> CX Reviews</p>
                                        <p><AiOutlineCheckCircle/> Courier &Insurance Claim management</p>
                                        <p><AiOutlineCheckCircle/> E-Invoicing with GST Portal</p>
                                        <p><AiOutlineCheckCircle/> MIS and Decision making system</p>
                                        <br/>

                                        <p><span>High Level Repair Center Service Application</span></p>
                                        <p><AiOutlineCheckCircle/> Incoming Defective Receipt Validations</p>
                                        <p><AiOutlineCheckCircle/> Discrepancy Stocks Management</p>
                                        <p><AiOutlineCheckCircle/> MSL and Demand forecast</p>
                                        <p><AiOutlineCheckCircle/> Parts Management</p>
                                        <p><AiOutlineCheckCircle/> Parts Cannibalization</p>
                                        <p><AiOutlineCheckCircle/> Service Engineer Schedule and Repair activity</p>
                                        <p><AiOutlineCheckCircle/> Engineer performance metrics</p>
                                        <p><AiOutlineCheckCircle/> Post Repair QC compliance</p>
                                        <p><AiOutlineCheckCircle/> Defective Units Scrap Management</p>
                                        <p><AiOutlineCheckCircle/> Warehousing and Logistics Management</p>
                                        <p><AiOutlineCheckCircle/> RMA/RTV Management</p>
                                        <br/>

                                        <p><span>Warehouse & Logistics Management</span></p>
                                        <p><AiOutlineCheckCircle/> Order Processing</p>
                                        <p><AiOutlineCheckCircle/> Centralized and Decentralized Warehouse model</p>
                                        <p><AiOutlineCheckCircle/> Automated order fulfillment</p>
                                        
                                        
                                        
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="slist">
                                    <p><AiOutlineCheckCircle/> Forecasting</p>
                                        <p><AiOutlineCheckCircle/> Purchase order processing</p>
                                        <p><AiOutlineCheckCircle/> Suppliers management</p>
                                    <p><AiOutlineCheckCircle/> Vendor Management</p>
                                        <p><AiOutlineCheckCircle/> Reverse Logistics</p>
                                        <p><AiOutlineCheckCircle/> Parts Cannibalization</p>
                                        <p><AiOutlineCheckCircle/> Manage inventory by lot #, Serial number/ expiration date</p>
                                        <p><AiOutlineCheckCircle/> Bin Management</p>
                                        <p><AiOutlineCheckCircle/> Pick Management (FIFO, Bin-priority, etc )</p>
                                        <p><AiOutlineCheckCircle/> Reduce Operational Expenses</p>
                                        <br/>

                                        <p><span>Exclusive 3PL Management</span></p>
                                        <p><AiOutlineCheckCircle/> Order Receipt from vendor</p>
                                        <p><AiOutlineCheckCircle/> Order Allocation</p>
                                        <p><AiOutlineCheckCircle/> Order fulfillment</p>
                                        <p><AiOutlineCheckCircle/> Purchase Order Generation and GRN</p>
                                        <p><AiOutlineCheckCircle/> Centralized and Decentralized Warehouse Management</p>
                                        <p><AiOutlineCheckCircle/> Logistics Management</p>
                                        <p><AiOutlineCheckCircle/> Forward & Reverse Logistics</p>
                                        <p><AiOutlineCheckCircle/> Defection collection</p>
                                        <p><AiOutlineCheckCircle/> Retrun to Vendor – RMA</p>
                                        <p><AiOutlineCheckCircle/> Digitalized Monitoring Metrics for Effective Management</p>
                                        <p><AiOutlineCheckCircle/> SLA Compliance Metrics</p>
                                        <p><AiOutlineCheckCircle/> Optimize Entire Process</p>
                                        <p><AiOutlineCheckCircle/> Improve Visibility on Operational Efficiency.</p>
                                        <br/>

                                        <p><span>Parts Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Part Planning</p>
                                        <p><AiOutlineCheckCircle/> Procurement Control</p>
                                        <p><AiOutlineCheckCircle/> Kitting & Grouping</p>
                                        <p><AiOutlineCheckCircle/> MSL Planning</p>
                                        <p><AiOutlineCheckCircle/> CKD / SKD Planning & Scheduling</p>
                                        <br/>

                                        <p><span>Inventory Management - Managing stock requests across various location</span></p>
                                        <p><AiOutlineCheckCircle/> Pick list generation (FIFO, Bin-priority, etc )</p>
                                        <p><AiOutlineCheckCircle/> Managing stocks through appropriate stockrooms</p>
                                        <p><AiOutlineCheckCircle/> Inventory Dashboards</p>
                                        <p><AiOutlineCheckCircle/> Daily stock auto reconciliation</p>
                                        <p><AiOutlineCheckCircle/> Daily uploading stock reports to vendor system</p>
                                        <p><AiOutlineCheckCircle/> RMA Management</p>
                                        <p><AiOutlineCheckCircle/> MSL Triggering Mechanism</p>
                                        <p><AiOutlineCheckCircle/> Auto alert mails and notifications</p>
                                        <br/>

                                        <p><span>Vendor Portal Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Integrated Vendor Management</p>
                                        <p><AiOutlineCheckCircle/> Calls/Jobs Status visibilities</p>
                                        <p><AiOutlineCheckCircle/> Stock transparency</p>
                                        <p><AiOutlineCheckCircle/> Approvals</p>
                                        <p><AiOutlineCheckCircle/> Filter & Eliminate Unwanted Procurement</p>
                                        

                                        
                                        

                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="slist">
                                    <p><AiOutlineCheckCircle/> Vendor Managed Inventory</p>
                                        <p><AiOutlineCheckCircle/> Schedule Adherence Rating</p>
                                        <br/>
                                    <p><span>Payroll Process Management</span></p>
                                        <p><AiOutlineCheckCircle/> Employee Directory</p>
                                    <p><AiOutlineCheckCircle/> Automate Payroll Process</p>
                                        <p><AiOutlineCheckCircle/> Leave & Attendance Update</p>
                                        <br/>

                                        <p><span>Human Resource Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Man power requisition</p>
                                        <p><AiOutlineCheckCircle/> Online application fulfillment and submission</p>
                                        <p><AiOutlineCheckCircle/> Managers Recommendation</p>
                                        <p><AiOutlineCheckCircle/> Man power assessment</p>
                                        <p><AiOutlineCheckCircle/> Final Process</p>
                                        <p><AiOutlineCheckCircle/> Employee Skill Matrix</p>
                                        <p><AiOutlineCheckCircle/> Candidate joining Process</p>
                                        <p><AiOutlineCheckCircle/> Employees Promotions</p>
                                        <p><AiOutlineCheckCircle/> Employee disengagement</p>
                                        <p><AiOutlineCheckCircle/> Exit Interview feedback</p>
                                        <p><AiOutlineCheckCircle/> Compliance Management</p>
                                        <p><AiOutlineCheckCircle/> Online attendance system</p>
                                        <p><AiOutlineCheckCircle/> Payroll process</p>
                                        <p><AiOutlineCheckCircle/> Outsources employee engagement and disengagement</p>
                                        <p><AiOutlineCheckCircle/> Employees portal – Leave/permission and attendance updates</p>
                                        <p><AiOutlineCheckCircle/> Salary Budgeting</p>
                                        <p><AiOutlineCheckCircle/> Employee KRA and appraisals </p>
                                        <br/>

                                        <p><span>Mobility Application (Mobile App on Android / IOS) for Work Force Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Scalable</p>
                                        <p><AiOutlineCheckCircle/> Online attendance system</p>
                                        <p><AiOutlineCheckCircle/> Automatic Engineer scheduling</p>
                                        <p><AiOutlineCheckCircle/> Customer feedback on repair closure</p>
                                        <p><AiOutlineCheckCircle/> Assigning resources with right skill set in the right place and at right time</p>
                                        <p><AiOutlineCheckCircle/> Status update on diagnosis and Repair actions</p>
                                        <p><AiOutlineCheckCircle/> Repair closure status updates</p>
                                        <p><AiOutlineCheckCircle/> Part request</p>
                                        <p><AiOutlineCheckCircle/> Improves asset uptime and customer satisfaction</p>
                                        <p><AiOutlineCheckCircle/> Real time Engineers movement and availability</p>
                                        <p><AiOutlineCheckCircle/> Real time status updates</p>
                                        <p><AiOutlineCheckCircle/> Accurate Travel allowance and pay out</p>
                                        <p><AiOutlineCheckCircle/> Work on offline (Non Network areas)</p>
                                        <p><AiOutlineCheckCircle/> API Integration</p>
                                        <p><AiOutlineCheckCircle/> Improved data Analytics and BI.</p>
                                        <br/>

                                        <p><span>Hospital Management System</span></p>
                                        <p><AiOutlineCheckCircle/> Doctors Appointment</p>
                                        <p><AiOutlineCheckCircle/> Out-Patient Module</p>
                                        <p><AiOutlineCheckCircle/> In-Patient Module</p>
                                        
                                        
                                        
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="slist">
                                    <p><AiOutlineCheckCircle/> Admission Module</p>
                                    <p><AiOutlineCheckCircle/> Medical Record Management</p>
                                    <p><AiOutlineCheckCircle/> Operation Theatre Module</p>
                                        <p><AiOutlineCheckCircle/> Dietary Module</p>
                                        <p><AiOutlineCheckCircle/> Room Services Modules</p>
                                        <br/>
                                    <p><AiOutlineCheckCircle/> Discharge Module</p>
                                        <p><AiOutlineCheckCircle/> Material Management Module</p>
                                        <p><AiOutlineCheckCircle/> Accounting and Finance Module</p>
                                        <p><AiOutlineCheckCircle/> Reports Module</p>
                                        <br/>
                                    <p><span>Call Centre Management system</span></p>
                                        <p><AiOutlineCheckCircle/> Customer Contact Information Details</p>
                                        <p><AiOutlineCheckCircle/> Technical /Non Technical Call center module</p>
                                        <p><AiOutlineCheckCircle/> Customer Complaints Details</p>
                                        <p><AiOutlineCheckCircle/> Product/Unit Details</p>
                                        <p><AiOutlineCheckCircle/> Customer Defective Unit Conditions</p>
                                        <p><AiOutlineCheckCircle/> Event Management (Inbound/ Outboud /Mails)</p>
                                        <p><AiOutlineCheckCircle/> Diagnosis and Repair Action details</p>
                                        <p><AiOutlineCheckCircle/> Call clousre</p>
                                        <br/>

                                        <p><span>Health Insurance BPO automation</span></p>
                                        <p><AiOutlineCheckCircle/> Insurance Eligibility Verification</p>
                                        <p><AiOutlineCheckCircle/> Patient Demographics details</p>
                                        <p><AiOutlineCheckCircle/> IICD, CPT and HCPCS Coding</p>
                                        <p><AiOutlineCheckCircle/> Charge Posting </p>
                                        <p><AiOutlineCheckCircle/> Agent validation and scrub</p>
                                        <p><AiOutlineCheckCircle/> Audit team verification</p>
                                        <p><AiOutlineCheckCircle/> Claims Submission, Electronic billing and manual billing</p>
                                        <p><AiOutlineCheckCircle/> Payment Posting</p>
                                        <p><AiOutlineCheckCircle/> Accounts Receivable Followup and Denial Management</p>
                                        <p><AiOutlineCheckCircle/> Patients Statement</p>
                                        <p><AiOutlineCheckCircle/> Reporting and Scheduling</p>
                                        <p><AiOutlineCheckCircle/> API Automation with Doctors Tool</p>
                                        <br/>

                                        <p><span>Customized Software Solutions</span></p>
                                        <p><AiOutlineCheckCircle/> Customization of existing system and its features in any platform or domain</p>
                                        <p><AiOutlineCheckCircle/> Change Management</p>
                                        <p><AiOutlineCheckCircle/> Reengineering</p>
                                        <p><AiOutlineCheckCircle/> Data based Maintenance</p>
                                        <p><AiOutlineCheckCircle/> Performance Fine tuning</p>
                                        <p><AiOutlineCheckCircle/> Skill set Training</p>
                                        <p><AiOutlineCheckCircle/> Software Consultation</p>
                                        <p><AiOutlineCheckCircle/> Trouble Shooting</p>
                                        <p><AiOutlineCheckCircle/> Database Migration</p>
                                        <p><AiOutlineCheckCircle/> Upgradation</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default SList;
