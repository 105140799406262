import Carousel from 'react-bootstrap/Carousel';
import "./Carousel.css"
import img1 from 'assets/img/carousel-1.jpg'
import img2 from 'assets/img/carousel-2.jpg'
import img3 from 'assets/img/carousel-3.jpg'

function UncontrolledExample() {
  return (
    <div>
      <div className="carousel" id="home">
        <div
          id="carouselExampleFade"
          className="carousel slide carousel-fade "
          data-bs-ride="carousel"
        >
          <div className="carousel-inner ">
            <div className="carousel-item active">
              <img src={img3} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img2} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={img1} className="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleFade"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="head">
              <h1 className='mt-2 mt-md-5 mt-lg-5'>We enjoy creating delightful, human-centered digital experiences and consumer products.</h1>
            </div>
          </div>
        </div>
      </div>
    </div>    
  );
}

export default UncontrolledExample;