import React,{useEffect} from 'react';

import Navbar from 'components/website/Navbar/Navbar';
import WHead from 'components/website/WHead/WHead';
import WAbout from 'components/website/WAbout/WAbout';
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import FixedChat from 'components/website/FixedChat/FixedChat';

const Wellness = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <WHead/>
            <WAbout/>
            <Contact/>
            <FixedChat/>
            <Footer/>
        </div>
    );
}

export default Wellness;
