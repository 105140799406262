import React from "react";
import "./DashNavbar.css";
import { FiMenu } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { useUserStore } from "stores/useUserStore";

const DashNavbar = () => {
  const { setuser } = useUserStore((s) => s);
  const handleToggle = () => {
    document.querySelector("body").classList.toggle("toggle-sidebar");
  };

  const handleLogout = () => {
    localStorage.clear();
    setuser({});
  };

  return (
    <header id="header" className="header d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between dashnav">
        <FiMenu className="bi bi-list toggle-sidebar-btn d-flex" onClick={handleToggle} />
        <h5 className=" ms-3 mb-0">Dashboard</h5>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {/* <li>
            <div className="search-bar">
              <form
                className="search-form d-flex align-items-center"
                method="POST"
                action="#"
              >
                <input
                  type="text"
                  autoFocus
                  name="query"
                  placeholder="Search..."
                />

                <button type="submit" title="Search" className="text-secondary">
                  <BiSearch className="bi bi-search" />
                </button>
              </form>
            </div>
          </li> */}
          {/* <li className="nav-item d-none search_icon">
            <a className="nav-link nav-icon search-bar-toggle" href="#i">
              <BiSearch />
            </a>
          </li> */}

          <li className="nav-item dropdown pe-4 ms-0 ms-lg-4 me-0 me-lg-3">
            <NavLink to="/" className="btn btn-primary" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </NavLink>

            {/* <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6 className="mb-1">User</h6>
              </li>
              <li>
                <NavLink
                  className="dropdown-item d-flex align-items-center"
                  to="/dashboard/view-profile"
                >
                  <i className="bi bi-person"></i>
                  <span>My Profile</span>
                </NavLink>
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="users-profile.html"
                >
                  <i className="bi bi-gear"></i>
                  <span>Account Settings</span>
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="#index"
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul> */}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default DashNavbar;
