import React,{useEffect} from 'react';
import Navbar from 'components/website/Navbar/Navbar'
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import EHead from 'components/website/EHead/EHead';
import EAbout from 'components/website/EAbout/EAbout';
import EProduct from 'components/website/EProduct/EProduct';
import FixedChat from 'components/website/FixedChat/FixedChat';

const Ecommerce = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <EHead/>
            <EAbout/>
            <FixedChat/>
            <EProduct/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Ecommerce;
