import React, {useEffect} from 'react';
import Navbar from 'components/website/Navbar/Navbar';
import LHead from 'components/website/LHead/LHead';
import LAbout from 'components/website/LAbout/LAbout';
import LWhereWeServe from 'components/website/LWhereWeServe/LWhereWeServe';
import LGoals from 'components/website/LGoals/LGoals';
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import FixedChat from 'components/website/FixedChat/FixedChat';

const Legal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <LHead/>
            <LAbout/>
            <LWhereWeServe/>
            <LGoals/>
            <FixedChat/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Legal;
