import React from 'react';
import './SAbout.css'
import { Container } from 'react-bootstrap';
import img from 'assets/img/software.jpg'

const SAbout = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="management">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="content">
                                <p>We leads you to innovation by consulting and delivering innovative idea to your requirements with ERPs, Software Development Application, Mobile and Web Applications, Desktop Applications, ITES.</p>

                                <p>We cover most of the applications used for the manufacturing industry, Freight and Supply chain industry, consumer industry, Healthcare industry, Travel and Logistics, and Application and Maintenance.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="simage">
                                 <img src={img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default SAbout;
