import React from "react";
import "./LAbout.css";
import { Container } from "react-bootstrap";
import img from "assets/img/legal.jpg";

const LAbout = () => {
  return (
    <div className="mt-5">
      <Container>
        <div className="management">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="content">
                <p>
                  <span>“Better that ten guilty persons escape, than that one innocent
                  suffer”
                  <br />– Sir William Blackstone (1765)
                  </span>
                </p>

                <p>
                  We offer our services to Individuals, Corporates, and the
                  Government sector. Our mission is to supply a comprehensive
                  and straightforward-to-understand legal solution to users and
                  create such a problem-solving and easy to seek out from
                  anywhere.
                </p>

                <p>
                  Need legal help?
                  <br />
                  <span>Speak with our Advocates </span>
                  <br />
                  <span>First Advice @ free of cost . </span>
                  <br />
                  <span>Legal Advice starting @ Rs.100/- </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="limage">
                <img src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LAbout;
