import React from 'react';
import './BMWhatWeDo.css'
import img from 'assets/img/what we do.jpg'
import { Container } from 'react-bootstrap';
import {AiOutlineCheckCircle} from 'react-icons/ai'

const BMwhatWeDo = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="bmwhatwedo">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <img src={img} className="whatwedoimg" alt="" width="100%" />
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="whatwedo">
                                <h2>What Do We Do?</h2>
                                <p>We offer personalized management consulting services for small and medium enterprises.</p>
                                <p>We design and implement strategies for businesses to survive in this competitive field.</p>
                                <p>We believe in the implementation of strategy in a professional manner.</p>
                                <p>We focus on what matters and what elements are effective for your business or what makes your business grow.</p>
                                <p> We believe in creative communication that brings creative ideas and creative outputs which launch the business into the path of SUCCESS.</p>
                            </div>
                        </div>
                        <div className="services mt-3">
                            <p>We agree to cover for you and provide the following services for starting a new business:</p>
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <div className="list">
                                        <p><AiOutlineCheckCircle/> MARKET STUDY</p>
                                        <p><AiOutlineCheckCircle/> CONNECTING SUPPLIERS</p>
                                        <p><AiOutlineCheckCircle/> RECRUITING</p>
                                        <p><AiOutlineCheckCircle/> SANCTIONS FROM LOCAL BODY GOVERNMENT BASED ON THE SCENARIO</p> 
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="list">
                                        <p><AiOutlineCheckCircle/> TAX CONSULTATIONY</p>
                                        <p><AiOutlineCheckCircle/> IMPLEMENTATION OF BUSINESS</p>
                                        <p><AiOutlineCheckCircle/> ADVERTISEMENTS AND SOCIAL MEDIA FLASHING</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="list">
                                        <p><AiOutlineCheckCircle/> BUSINESS LEGAL CONSULTING</p>
                                        <p><AiOutlineCheckCircle/> ADVICES FOR BUSINESS IMPROVEMENT</p>
                                        <p><AiOutlineCheckCircle/> TARGET AUDIENCE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default BMwhatWeDo;
