import React from 'react';
import './WAbout.css'
import { Container } from 'react-bootstrap';
import img from 'assets/img/wellness.jpg'

const WAbout = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="management">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="content">
                                <p>We are an associate degree consulting team specializing in providing wellness and healthcare advice, counseling, and academic services for children, adolescents, and adults. Our consultants are extremely skilled practitioners registered with the Health & Care Professions Council.</p>
                                <p>Our team includes Wellness experts: Nutritionist, Clinical Psychologists, Counsellors, Counselling Psychologists, Child  Psychologists, and Suicide prevention counselors. We extend our service to Individuals of all age groups, Families, Employees, Organizations, Hospitals, Schools, and Colleges.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="wimage">
                            <img src={img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default WAbout;
