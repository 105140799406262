import React from 'react';
import { NavLink } from 'react-router-dom';
import {FaWhatsappSquare} from 'react-icons/fa';
import './FixedChat.css';

const FixedChat = () => {
    return (
        <div className="chat">
            <NavLink to="https://wa.me/message/34UPPFHDFJLSI1">
                <FaWhatsappSquare />
              </NavLink>
        </div>
    );
}

export default FixedChat;
