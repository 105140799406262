import React, { useState }  from "react";
import "./WTable.css";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import { alertPromise } from "components/AppAlert/AppAlert";
import privateAPI from "api/privateAPI";
import { useUserStore } from "stores/useUserStore";

const WTable = () => {
  const { user } = useUserStore((s) => s);
  const [wellness, setWellness] = useState({
    name:"", 
    age:"", 
    gender:"", 
    phone:"", 
    email:"", 
    address:"",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setWellness({ ...wellness, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await alertPromise(privateAPI.post("/wellness", { ...wellness, user_id: user?.user_id }));
    e.target.reset();
  };
  return (
    <div>
      <div className="dash-wellness mt-4">
        <h4>Wellness Consulting</h4>
      </div>
      <div className="row mt-3">
        <div className="col-lg-5 col-sm-12 col-md-6">
          <div className="wtable">
            <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name"onChange={onChange} name="name" placeholder="Enter name " />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupNumber">
                <Form.Label>Age</Form.Label>
                <Form.Control type="name" onChange={onChange} name="age" placeholder="Enter Age " />
              </Form.Group>

              <Form.Group
                  className="mb-3"
                  controlId="formHorizontalAge"
                >
                  <Form.Label>
                    Gender
                  </Form.Label>
                  {["radio"].map((type) => (
                      <div key={`reverse-${type}`} className="mb-3  d-flex">
                        <Form.Check
                        onChange={onChange} name="gender"
                          reverse
                          label="Male"
                          type={type}
                          value="Male"
                          className="me-4"
                        />
                        <Form.Check
                          reverse
                          label="Female"
                          onChange={onChange} name="gender"
                          type={type}
                          value="Female"
                          id={`reverse-${type}-2`}
                        />
                      </div>
                    ))}
                </Form.Group>
              

              <Form.Group className="mb-3" controlId="formGroupPhone">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="tel" onChange={onChange} name="phone"placeholder="Enter Mobile number" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" onChange={onChange} name="email" placeholder="Enter email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupText">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" onChange={onChange} name="address" placeholder="Enter address" />
              </Form.Group>

              <Button variant="primary me-3" type="submit">Submit</Button>
            </Form>
          </div>
          <div className="col-lg-7 col-md-6"></div>
        </div>
      </div>
    </div>
  );
};

export default WTable;
