import React from "react";
import './Dashboard.css'
import DashNavbar from "components/website/DashNavbar/DashNavbar";
import {Outlet} from "react-router-dom";
import Sidebar from "components/website/DashSidebar/Sidebar";

const Dashboard = () => {
  return (
    <>
      <Sidebar/>
      <div className="dash-content">
      <main id="main" className="main">
        <DashNavbar />
        <div className="pb-3 pt-1 px-3 px-lg-4">
          <Outlet />
        </div>
      </main>
      </div>

    </>
  );
};

export default Dashboard;
