import React from "react";
import "./Sidebar.css";
import { FiGrid } from "react-icons/fi";
import img from "assets/img/login-bg6.jpg";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { IoMdStats } from "react-icons/io";
import { FaUserTie } from "react-icons/fa";
import { FaPumpSoap } from "react-icons/fa";
import { FaBookReader } from "react-icons/fa";
import { FaCommentMedical } from "react-icons/fa";
import { FaClinicMedical } from "react-icons/fa";
import { useUserStore } from "stores/useUserStore";

const Sidebar = () => {
  const {user}=useUserStore(s=>s)
  return (
    <aside id="sidebar" className="sidebar">
      <div className="text-center logo mb-4">
        <h4>MyIncorp</h4>
      </div>
      <div className="text-center">
        <a href="#index">
          <img src={img} alt="img" className="profile_img" />
        </a>
        <h6 className="mt-3 mb-1 mb-0 text-white name">{user?.name}</h6>

        {/* <span className="d-block mt-2">
          <NavLink to="/">
            <AiFillHome className="text-success" title="Home" />
          </NavLink>

          <TbPower
            className="text-danger ms-3 fs-5 cursor-pointer"
            title="Logout"
          />
        </span> */}
      </div>
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* <li className="nav-item">
          <Link
            to="/dashboard/join-member"
            className="nav-link app_btn success"
          >
            <MdPersonAddAlt1 className="me-2 fs-5" />
            Join Memeber
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/subscription"
            className="nav-link app_btn success"
          >
            <MdPayments className="me-2 fs-5" />
            Make Payment
          </Link>
        </li> */}
        <li className="nav-heading">Pages</li>
        <span>
          <li className="nav-item">
            <Link to="/dashboard/business" className="nav-link">
              <FaPumpSoap />
              <span className="ms-2">Business Consulting</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/dashboard/software" className="nav-link">
              <FaBookReader />
              <span className="ms-2">Software Consulting</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/dashboard/legal" className="nav-link">
              <FaCommentMedical />
              <span className="ms-2">Legal Consulting</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/dashboard/wellness" className="nav-link">
              <FaClinicMedical />
              <span className="ms-2">Wellness Consulting</span>
            </Link>
          </li>
        </span>
      </ul>
    </aside>
  );
};

export default Sidebar;
