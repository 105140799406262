import React,{useEffect} from 'react';
import Navbar from 'components/website/Navbar/Navbar';
import SHead from 'components/website/SHead/SHead';
import SAbout from 'components/website/SAbout/SAbout'
import Contact from 'components/website/Contact/Contact';
import Footer from 'components/website/Footer/Footer';
import SFaq from 'components/website/SFaq/SFaq';
import SList from 'components/website/SLists/SList';
import FixedChat from 'components/website/FixedChat/FixedChat';

const Software = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            <Navbar/>
            <SHead/>
            <SAbout/>
            <SList/>
            <FixedChat/>
            <SFaq/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Software;
