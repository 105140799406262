import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "pages/website/Home/Home";
import BusinessManagement from "pages/website/Home/BusinessManagement";
import Software from "pages/website/Home/Software";
import Legal from "pages/website/Home/Legal";
import Wellness from "pages/website/Home/Wellness";
import Register from "pages/website/Register/Register";
import Dashboard from "pages/webapp/Dashboard/Dashboard";
import BTable from "components/website/DashBTable/BTable";
import STable from "components/website/DashSTable/STable";
import LTable from "components/website/DashLTable/LTable";
import WTable from "components/website/DashWTable/WTable";
import Career from "pages/website/Home/Career";
import Ecommerce from "pages/website/Home/ecommerce";
import publicAPI from "api/publicAPI";
import { useUserStore } from "stores/useUserStore";
import ViewBusiness from "components/webApp/ViewBusiness/ViewBusiness";
import ViewSoftware from "components/webApp/ViewSoftware/ViewSoftware";
import ViewLegal from "components/webApp/ViewLegal/ViewLegal";
import ViewWellness from "components/webApp/ViewWellness";
const AppRoutes = () => {
  const isUserFound = localStorage.getItem("MYINCORP_USERID");
  const { setUser, user } = useUserStore((s) => s);

  useEffect(() => {
    async function getLoginUser() {
      const { data } = await publicAPI.get(`/user/${isUserFound}`);
      setUser(data?.appData[0]);
    }

    if (isUserFound) getLoginUser();
  }, [isUserFound, setUser]);

  console.log(user.role);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/business-Management" element={<BusinessManagement />} />
          <Route path="/software" element={<Software />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/wellness" element={<Wellness />} />
          <Route path="/career" element={<Career />} />
          <Route path="/register" element={<Register />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/dashboard" element={<Dashboard />}>
            {user?.role === 1 ? (
              // Admin Routes
              <>
                <Route path="business" element={<ViewBusiness />} />
                <Route path="software" element={<ViewSoftware />} />
                <Route path="legal" element={<ViewLegal />} />
                <Route path="wellness" element={<ViewWellness />} />
              </>
            ) : (
              // user routes
              <>
                <Route path="business" element={<BTable />} />
                <Route path="software" element={<STable />} />
                <Route path="legal" element={<LTable />} />
                <Route path="wellness" element={<WTable />} />
              </>
            )}
            <Route path="business" element={<BTable />} />
            <Route path="software" element={<STable />} />
            <Route path="legal" element={<LTable />} />
            <Route path="wellness" element={<WTable />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
