import React from 'react';
import './WHead.css'
import img from 'assets/img/carousel-3.jpg'

const WHead = () => {
  return (
    <div className="position-relative">
        <div className="image">
          <img src={img} alt="" />
        </div>
        <div className="text">
          <h3>WELLNESS PHARMA</h3>
          <p>Good health is above wealth </p>
        </div>
    </div>
  );
}

export default WHead;