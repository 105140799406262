import React from 'react';
import './Category.css'
import { Container } from 'react-bootstrap';
import { MdBusiness } from 'react-icons/md'
import { FaBookReader } from 'react-icons/fa'
import { GoLaw } from 'react-icons/go'
import { FaClinicMedical } from 'react-icons/fa'
import { NavLink } from 'react-router-dom';
import {FiShoppingCart} from 'react-icons/fi'

const Category = () => {
    return (
        <div id="Categories">
            <div className="category mt-5">
                <Container>
                        <div className="row">
                            <div className="col">
                                <NavLink to="business-Management">
                                    <div className="business">
                                        <div className="icon">
                                            <MdBusiness/>
                                        </div>
                                        <div className="head">
                                            <h3>Business Management Consulting</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col">
                                <NavLink to="software">
                                    <div className="software">
                                        <div className="icon">
                                            <FaBookReader/>
                                        </div>
                                        <div className="head">
                                            <h3>Software Consulting</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col">
                            <NavLink to="legal">
                                    <div className="legal">
                                        <div className="icon">
                                            <GoLaw/>
                                        </div>
                                        <div className="head">
                                            <h3>Legal Consulting</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col">
                            <NavLink to="wellness">
                                    <div className="wellness">
                                        <div className="icon">
                                            <FaClinicMedical/>
                                        </div>
                                        <div className="head">
                                            <h3>Wellness Consulting</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col">
                                <NavLink to="ecommerce">
                                    <div className="e-com">
                                        <div className="icon">
                                            <FiShoppingCart/>
                                        </div>
                                        <div className="head">
                                            <h3>E - Commerce</h3>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            
                        </div>
                </Container>
            </div>
        </div>
    );
}

export default Category;
