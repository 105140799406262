import React from 'react';
import { Container } from 'react-bootstrap';
import './BMWhyWeHere.css';
import {BsArrowRightCircle} from 'react-icons/bs'

const BMWhyWeHere = () => {
    return (
        <div className='mt-5'>
            <Container>
                <div className="box">
                    <div className="head">
                         <h2>Why Are We Here?</h2>
                    </div>
                    <div className="whywehere">
                        <p><BsArrowRightCircle/> TO PROVIDE RIGHT SERVICE AT THE RIGHT TIME FOR YOUR BUSINESS </p>
                        <p><BsArrowRightCircle/> To provide great ideas to implement for your business enhancements </p>
                        <p><BsArrowRightCircle/> TO indulge in LONG TERM business COMMITMENTS AND CLIENT RELATIONSHIPS with you, WHICH would take your  BUSINESS to the next level  </p>
                        <p><BsArrowRightCircle/> To  TRANSFER KNOWLEDGE AND EXPERTISE we have to your business </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default BMWhyWeHere;
