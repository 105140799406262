import React from "react";
import { Container } from "react-bootstrap";
import "./EProduct.css";
import img from "assets/img/product.png";
import { FaRupeeSign } from "react-icons/fa";
import img1 from "assets/img/garments.jpg";

const EProduct = () => {
  return (
    <div>
      <div className="mt-5">
        <Container>
          <div className="row">
            <div className="col-lg-2 col-sm-12 col-12">

            </div>
            <div className="col-lg-4 col-sm-12 col-md-6">
              <div className="eproduct-box">
                <div className="eitemswash">
                  <img src={img} alt="" />
                  <div className="row">
                    <div className="col-8">
                      <h3>Detergent Liquid</h3>
                    </div>
                    <div className="col-4">
                      <h4>
                        <FaRupeeSign /> 199
                      </h4>
                    </div>
                  </div>

                  <p>Smooth Wash Solution</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-6">
            <div className="eproduct-box">
                <div className="eitemsgarments">
                  <img src={img1} alt=""  />
                  <h3>Garments</h3>
                  {/* <div className="row">
                    <div className="col-8">
                      <h3>Garments</h3>
                    </div>
                    <div className="col-4">
                      <h4>
                        <FaRupeeSign /> 100
                      </h4>
                    </div>
                  </div> */}

                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-12">

            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EProduct;
