import React from 'react';
import './SHead.css'
import img from 'assets/img/carousel-3.jpg'

const SHead = () => {
  return (
    <div className="position-relative">
        <div className="image">
          <img src={img} alt="" />
        </div>
        <div className="text">
          <h3>SOFTWARE CONSULTING</h3>
        </div>
    </div>
  );
}

export default SHead;